import route from '@router/route';
import { backend } from '.';

export const JOB_TYPES = {
    ALL_JOBS: 1,
    STDR_JOBS_ONLY: 2,
};

export function storeCareerPreferences(payload) {
    return backend
        .post(route('career.corner.preferences.store.preference'), { careerPreferences: payload })
        .then((response) => response.data);
}

export function updateCareerPreferences(payload) {
    return backend.put(route('career.corner.preferences.update', { id: payload.id }), { careerPreferences: payload });
}

export function updateOrCreateCareerPreferenceLocations(payload) {
    return backend.post(route('career.corner.job.preferences.update.locations', payload));
}

export function editorials(payload) {
    return backend.post(route('career.corner.editorials'), { excludes: payload }).then((response) => response.data);
}

export function deleteCareerCornerProfilePicture() {
    return backend.delete(route('career.corner.profile.picture.destroy.picture')).then((response) => response.data);
}

export function saveOrUpdateUserJobAction(payload) {
    return backend
        .post(route('career.corner.update.user.job.action'), { ...payload })
        .then((response) => response.data);
}

export function fetchUserJobSwipeList(page = 1) {
    return backend
        .get(route('career.corner.user.job.swipe.list'), {
            params: {
                page,
            },
        })
        .then((response) => response.data);
}

export function fetchJobsVibeByScore(page = 1) {
    return backend
        .get(route('career.corner.user.job.vibes.score.list'), {
            params: {
                page,
            },
        })
        .then((response) => response.data);
}

export function fetchJobsVibeByDate(page = 1) {
    return backend
        .get(route('career.corner.user.job.vibes.date.list'), {
            params: {
                page,
            },
        })
        .then((response) => response.data);
}

export function fetchAllJobList(page = 1, type = JOB_TYPES.ALL_JOBS) {
    return backend
        .get(route('career.corner.all.job.list'), {
            params: {
                page,
                type,
            },
        })
        .then((response) => response.data);
}

export function fetchWidgetList() {
    return backend.get(route('career.corner.widget.job.list')).then((response) => response.data);
}

export function retrieveCareerCornerSurvey() {
    return backend.get(route('career.corner.survey.get'));
}

export function careerCornerCreateUpdateUserSurvey(action, survey) {
    return backend.post(route('career.corner.survey.post', survey), { survey, action });
}

export async function storeJobSeekers() {
    await backend.post(route('job-seeker')).catch();
}

export function getUserNotificationsCount() {
    return backend.get(route('career.corner.user.notifications.count')).then((response) => response.data);
}

export function markUserNotificationAsRead() {
    return backend.post(route('career.corner.mark.user.notifications.as.read'));
}

export function getCompanyJobsById(companyId) {
    return backend
        .get(route('career.corner.get.company.jobs.by.id'), {
            params: {
                company_id: companyId ?? null,
            },
        })
        .then((response) => response.data);
}

export async function getCareerCornerUserActions() {
    return backend.get(route('career.corner.get.user.actions')).then((response) => response.data);
}

export function updateCareerCornerUserActions(data) {
    return backend
        .post(route('career.corner.update.user.actions'), { ...data })
        .then((response) => response.data?.data);
}
