import type { DataResponse } from '@api/types';
import { gateway } from '..';
import type { UserProfileResource } from './resources/UserProfileResource';
import type { GeolocationResource } from './resources/GeolocationResource';

export function getUserProfile(userId: number) {
    return gateway.get<UserProfileResource>(`/legacy-api/v1/profiles/${userId}`).then((response) => response.data);
}

export function getGeolocationFromIp() {
    return gateway.get<GeolocationResource>('/legacy-api/v1/geolocation').then((response) => response.data);
}

export function setUsername(username: string) {
    return gateway.post<DataResponse<unknown>>('/legacy-api/v1/users/nickname', { nickname: username });
}
