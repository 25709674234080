import store from '@/store';
import { getPreviousLocation, keepUrl } from '@/utils/routeUtils';
import type { NavigationGuard } from 'vue-router';
import type { FeatureName } from '@/modules/features';
import { featureIsEnabled } from '@/modules/features';

/**
 * This guard only permits accessing a route if the user is logged in.
 * Otherwise, a login prompt will be shown on the current page or a 403 page if no current page exists
 */
export const authGuard: NavigationGuard = (to, from) => {
    if (store.getters['auth/isLoggedIn']) {
        return true;
    }

    store.dispatch('ui/openLogin');

    // we only navigate to the error page if no previous page was loaded
    return from.matched.length
        ? false
        : {
              name: 'error',
              replace: true,
              state: {
                  errorCode: '403',
              },
          };
};

/**
 * This guard will keep the current route url regardless of the path.
 * This only works with routes that have the `pathMatch` param defined in their path,
 * as it will specify that parameter if not set.
 */
export const keepUrlGuard: NavigationGuard = (to, from) => {
    // Whenever we encounter this, we can assume it was already replaced.
    if (to.params.pathMatch) return true;

    return keepUrl({ name: to.name ?? undefined }, getPreviousLocation(to, from));
};

/**
 * This guard will make sure that our global `lang` param is set for all our routes
 * so that we don't need to specify it ourselves.
 */
export const langGuard: NavigationGuard = (to) => {
    if (to.matched.some((route) => route.path.includes(':lang')) && !to.params.lang) {
        return {
            name: to.name ?? undefined,
            path: to.path,
            params: {
                ...to.params,
                lang: store.getters['ui/languageCode'],
            },
            query: to.query,
            hash: to.hash,
        };
    }
    return true;
};

/**
 * Routes to the error page based on the meta http-status of the page set by the server
 */
export const pageStatusGuard: NavigationGuard = (to, from) => {
    // This condition will be met if a route was loaded before and we are navigating to a new route.
    // Which means, we need to reset the page status as we display a new page to the user.
    if (from.matched.length) {
        document.querySelector('meta[name="http-status"]')?.remove();
        return true;
    }

    // the server will set the page status code in the meta tag
    const code = document.querySelector('meta[name="http-status"]')?.getAttribute('content') ?? '200';

    // we don't need to navigate to the error page if there is no error or the error code is the same
    if (code === '200' || (to.name === 'error' && window.history.state.errorCode?.toString() === code)) {
        return true;
    }

    window.history.state.errorCode = code;
    return { name: 'error', state: { errorCode: code } };
};

export const featureEnabledGuard =
    (featureName: FeatureName): NavigationGuard =>
    () => {
        if (!featureIsEnabled(featureName)) {
            return {
                name: 'error',
                replace: true,
                state: {
                    errorCode: '404',
                },
            };
        }
        return true;
    };

export const redirectIfLoggedInGuard =
    (destination: string): NavigationGuard =>
    (to) => {
        if (store.getters['auth/isLoggedIn']) {
            return { name: destination, replace: true, params: { lang: to.params.lang } };
        }
        return true;
    };

export const redirectIfLoggedInPupilGuard =
    (destination: string): NavigationGuard =>
    (to) => {
        const user = store.getters['auth/user'];
        if (user?.is_pupil) {
            return {
                name: destination,
                replace: true,
                params: { lang: to.params.lang },
                ...(to.query ? { query: to.query } : {}),
            };
        }
        return true;
    };
